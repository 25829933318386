<template>
	<div class="pt20 pr20 pb20 pl20">
		<p data-v-7e4377da="" class="dotted-title">近30天商品数据</p>
		<div data-v-7e4377da="" class="liveCommodity">
			<div data-v-7e4377da="" class="liveleft">
				<div data-v-7e4377da="" class="liveimg">
					<img
						data-v-7e4377da=""
						alt=""
						src="//gw.alicdn.com/bao/uploaded/i4/2208050260574/O1CN01LLg6yN1G6w1loHCVH_!!0-item_pic.jpg"
					/>
				</div>
				<div data-v-7e4377da="" class="liveshop">
					<p data-v-7e4377da="" class="pointer">
						【薇娅618专享】叮叮懒人菜老坛酸菜鱼半成品快手菜黑鱼450g*3袋
					</p>
					<div data-v-7e4377da="">
						商品来源： <span data-v-7e4377da="">淘宝</span>
					</div>
					<div data-v-7e4377da="">
						商品品类： <span data-v-7e4377da="">百货食品</span>
					</div>
				</div>
			</div>
			<div data-v-7e4377da="" class="liveright">
				<div data-v-7e4377da="">
					<p data-v-7e4377da="">合作主播数</p>
					<span data-v-7e4377da="" class="f24">1人</span>
				</div>
				<div data-v-7e4377da="" class="cut-line"></div>
				<div data-v-7e4377da="">
					<p data-v-7e4377da="">被播次数</p>
					<span data-v-7e4377da="" class="f24">1次</span>
				</div>
				<div data-v-7e4377da="" class="cut-line"></div>
				<div data-v-7e4377da="">
					<p data-v-7e4377da="">直播价格</p>
					<span data-v-7e4377da="" class="f24">¥120</span>
				</div>
			</div>
		</div>
		<p data-v-7e4377da="" class="dotted-title">商品主播带货数据</p>
		<div class="dataCommodity flex_r">
			<div class="flex1">
			<div data-v-7e4377da="" class="box box3">
				<div data-v-7e4377da="" class="col">
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">总销量</div>
						<div data-v-7e4377da="" class="coldata data1">5163</div>
					</div>
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">总销售额</div>
						<div data-v-7e4377da="" class="coldata">¥61.96万</div>
					</div>
				</div>
				<div data-v-7e4377da="" class="col">
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">场均销量</div>
						<div data-v-7e4377da="" class="coldata data1">5163件</div>
					</div>
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">场均销售额</div>
						<div data-v-7e4377da="" class="coldata">¥61.96万</div>
					</div>
				</div>
				<div data-v-7e4377da="" class="col">
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">单场最高销量</div>
						<div data-v-7e4377da="" class="coldata data1">5163件</div>
					</div>
					<div data-v-7e4377da="">
						<div data-v-7e4377da="">单场最高销售额</div>
						<div data-v-7e4377da="" class="coldata">¥61.96万</div>
					</div>
				</div>
			</div>
			</div>
		</div>
		
		<p data-v-7e4377da="" class="dotted-title">合作主播场次</p>
		<e-table :tableCols="goodsDetailDataCenterTableConf" :dataOrigin="dataOrigin">
			<template #2>
				<div class="flex_r">
					<img class="pr10" height="40" width="40" src="http://img.alicdn.com/sns_logo/i2/69226163/TB2YEitX3aTBuNjSszfXXXgfpXa_!!69226163-0-beehive-scenes.jpg_250x250q100.jpg" alt="">
					<div>
						<div class="tl">薇娅viya</div>
						<span>ID： 69226163</span>
					</div>
				</div>
			</template>
			<template #6>
				<router-link tag="a" target="_blank" :to="{path:'/activeDetails'}">查询详情</router-link>
			</template>
		</e-table>
	</div>
</template>

<script>
import {goodsDetailDataCenterTableConf} from '@data/anchorData'
export default {
	data() {
		return {
			goodsDetailDataCenterTableConf,
			dataOrigin: {
				data: [
					{}
				]
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.liveCommodity {
    border-radius: 4px;
    background-color: #f8f8f8;
    display: flex;
    padding: 26px;
    font-size: 16px;
}
.liveCommodity .liveleft {
    display: flex;
    flex: 2;
}
.liveCommodity .liveleft .liveimg {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
}
.liveCommodity .liveleft .liveimg img {
    width: 100%;
    height: 100%;
}
.liveCommodity .liveleft .liveshop {
    flex: 1;
    color: #8d8d8d;
    max-width: 320px;
}
.liveCommodity .liveleft .liveshop>p {
    font-size: 20px;
    color: #101011;
    margin: 10px 0 14px;
}
.liveCommodity .liveleft .liveshop>div {
    font-size: 16px;
    margin-bottom: 14px;
}
.liveCommodity .liveright {
    display: flex;
    flex: 3;
    justify-content: space-around;
    align-items: center;
}
.liveCommodity .liveright .cut-line {
    width: 1px;
    height: 40px;
    background: #e5e5e5;
}
.liveCommodity .liveright>div p {
    color: #8b8b8d;
    font-size: 14px;
    margin: 0 0 16px 0;
}
.liveCommodity .liveright>div span {
    color: #ff474a;
    font-size: 20px;
}
.dotted-title {
    color: #030303;
    font-size: 14px;
    padding-left: 12px;
    position: relative;
    margin: 20px 0;
}
.dataCommodity .box {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    min-height: 297px;
    box-sizing: border-box;
}
.dataCommodity .box.box3 {
    padding: 54px 69px;
    display: flex;
}
.dataCommodity .box.box3 .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    color: #8b8b8d;
}
.dataCommodity .box.box3 .col .coldata {
    color: #ff474a;
    font-size: 20px;
    margin-top: 10px;
}

</style>